import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({

  baseURL: `https://api.shope.b2gsoft.com/api/v1`,


  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
 
});


export default axios;
